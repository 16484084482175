<template>
  <!-- 编码:lzq -->
  <div>
    <main>
      <div class="banner">
        <div>
          <img v-lazy="require('./wwjcyjxt_img/banner.webp')" alt="" />
        </div>
        <span class="wwyjxt">文物监测预警系统</span>
        <span class="znjc"
          >智能监测预警平台，实现文物保护的可视互联，解决文物安全、管理、研究、展示、利用、共享等方面的需求</span
        >
      </div>
      <h2 class="title_h2">庐山国家公园遗产地（文化遗产）监测预警系统项目</h2>
      <!-- 庐山国家公园遗产地（文化遗产）监测预警系统项目 -->
      <div class="system">
        <div class="system_left">
          <img v-lazy="require('./wwjcyjxt_img/image1.webp')" alt="" />
        </div>
        <div class="system_right">
          <h3>监测预警系统项目</h3>
          <p>
            以中国世界文化遗产监测预警体系所包含的制度规范系统、人力资源系统和工程技术系统为依托，以预防性保护监测作为核心理念，通过部署智能传感设备、网络设备，并开发相应的软件监测系统，构建庐山国家公园遗产地（文化遗产）监测预警系统，对庐山国家公园世界遗产面临的风险以及可能会出现的病害进行系统监测。通过存储、处理和分析挖掘采集的文化遗产价值载体的保存状态信息、面临的风险信息等，为风险识别提供数据支持，为风险评估提供相关的标准和风险评估模型，为遗产保护提供科学的决策依据
          </p>
        </div>
      </div>
      <!-- 文化遗产物联网检测管理云平台 -->
      <div class="platform">
        <h2>文化遗产物联网检测管理云平台</h2>
        <p>
          智能检测预警平台，实现文物保护的可视互联，解决文物安全、管理、研究、展示、利用、共享等方面需求
        </p>
        <div class="img_box">
          <div>
            <img v-lazy="require('./wwjcyjxt_img/bg.webp')" alt="" />
          </div>
          <p>解决文物安全问题闭环：文物云监测预警系统</p>
        </div>
        <!-- 底部4栏 -->
        <div class="foot_box">
          <div class="box_img">
            <div><img src="./wwjcyjxt_img/icon1.png" alt="" /></div>
            <p>实时数据监控</p>
          </div>
          <div class="box_img">
            <div><img src="./wwjcyjxt_img/icon2.png" alt="" /></div>
            <p>预警阈值设定</p>
          </div>
          <div class="box_img">
            <div><img src="./wwjcyjxt_img/icon3.png" alt="" /></div>
            <p>安全警报</p>
          </div>
          <div class="box_img">
            <div><img src="./wwjcyjxt_img/icon4.png" alt="" /></div>
            <p>跟踪分析</p>
          </div>
        </div>
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>

<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.banner {
  width: 100%;
  height: 600px;
  position: relative;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // loading大小
    img[lazy="loading"] {
      width: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  .wwyjxt {
    min-width: 417px;
    height: 67px;
    font-size: 48px;
    color: #ffffff;
    position: absolute;
    top: 230px;
    left: 360px;
    text-align: left;
  }
  .znjc {
    min-width: 697px;
    height: 66px;
    font-size: 24px;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 317px;
    left: 360px;
    text-align: left;
  }
}
//版心
.title_h2 {
  font-size: 40px;
  font-weight: 500px;
  color: #333;
  letter-spacing: 1px;
  margin-bottom: 60px;
  margin-top: 80px;
}
.system {
  width: 1200px;
  height: 420px;
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 0 auto;
  margin-bottom: 100px;
  display: flex;
  .system_left {
    width: 544px;
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    // loading大小
    img[lazy="loading"] {
      width: 150px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  .system_right {
    text-align: left;
    width: 656px;
    height: 100%;
    padding: 24px 48px 39px 48px;
    h3 {
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      letter-spacing: 1px;
      margin-bottom: 24px;
    }
    p {
      height: 288px;
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      line-height: 32px;
    }
  }
}
.platform {
  width: 100%;
  height: 959px;
  background: #f6f8fb;
  padding-top: 80px;
  h2 {
    height: 56px;
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
  }
  p {
    margin-top: 23px;
    margin-bottom: 60px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    color: #5f5f5f;
    line-height: 40px;
    letter-spacing: 1px;
  }
  .img_box {
    position: relative;
    width: 1200px;
    height: 420px;
    margin: 0 auto;
    margin-bottom: 30px;
    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // loading大小
      img[lazy="loading"] {
        width: 200px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        width: 100%;
        height: 100%;
      }
    }
    p {
      position: absolute;
      top: 183px;
      left: 187px;
      height: 56px;
      font-size: 40px;
      font-weight: 400;
      color: #ffffff;
      line-height: 56px;
      letter-spacing: 1px;
    }
  }
  .foot_box {
    width: 1200px;
    height: 150px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .box_img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 282px;
      height: 150px;
      background: #ffffff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.1);
      div {
        width: 64px;
        height: 64px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        margin: 0;
        margin-left: 24px;
        height: 28px;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
      }
    }
  }
}
</style>
